body {
  background: #000;
  margin: 0px;
  font-family: "Gilroy-Regular";
  font-size: "14px";
}

@font-face {
  font-family: "Gilroy-Bold";
  src: local("Gilroy-Bold"), url(../fonts//Gilroy-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-Medium";
  src: local("Gilroy-Medium"), url(../fonts//Gilroy-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-Light";
  src: local("Gilroy-Light"), url(../fonts//Gilroy-Light.ttf) format("truetype");
}
@font-face {
  font-family: "Gilroy-Regular";
  src: local("Gilroy-Regular"),
    url(../fonts//Gilroy-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Gilroy-SemiBold";
  src: local("Gilroy-SemiBold"),
    url(../fonts//Gilroy-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "Gilroy-ExtraBold";
  src: local("Gilroy-ExtraBold"),
    url(../fonts//Gilroy-SemiBold.ttf) format("truetype");
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.displayCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.bg_common {
  fill: #000;
  backdrop-filter: blur(75px);
}
.card_common {
  border-radius: 10px;
  border: 0.8px solid rgba(255, 255, 255, 0.05);
  background: #101010;
  cursor: pointer;
}
.dispalySpacebetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.display_center {
  display: flex;
  align-items: center;
}
.border_radius_10 {
  border-radius: 10px;
}
.walletContainer {
  border-radius: 10px;
  border: 1px solid #374d69;
  cursor: pointer;
}
.textLinearGradient {
  color: #acfd00 !important;
  text-align: center;
  font-family: Gilroy-SemiBold;
  font-size: 25px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 12px;
}
///Auto filled webkit
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #fff;
  transition: background-color 5000s ease-in-out 0s;
}
///spin button for phone input
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #fff;
  transition: background-color 5000s ease-in-out 0s;
}
::-webkit-scrollbar {
  display: none !important;
}
.result-bar {
  height: 30px;
  line-height: 30px;
  color: #fff;
  text-align: center;
  font-weight: bold;
  display: flex;
  align-items: center;
  border-radius: 3px;
}
.result-bar.yes {
  background-color: #4caf50; /* Green */
}
